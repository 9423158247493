<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Users</h3>
            </div>
            <div class="card-toolbar">
              <b-button variant="outline-primary" v-b-modal.add-user-modal
                >Add User</b-button
              >
            </div>
          </div>
          <div class="card-body body-fluid">
            <b-table striped hover :items="users" :fields="fields">
              <template #cell(id)="row">                
                <b-button @click="ShowEditModal(row.item.id)" variant="primary" class="mr-1"
                  >Edit</b-button>
                  <b-button @click="deleteUserModal(row.item.id)" variant="danger"
                  >Delete</b-button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    
    <b-modal
      id="add-user-modal"
      ref="modal"
      size="xl"
      @hidden="resetModal"
      @ok="adduser"
      title="Add User"
    >
      <form ref="editform" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6">
            <b-form-group label="First Name">
              <b-form-input v-model="form.first_name"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Last Name">
              <b-form-input v-model="form.last_name"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Email">
              <b-form-input v-model="form.email"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Password">
              <b-form-input
                type="password"
                v-model="form.password"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Role">
              <b-form-select
                v-model="form.role"
                :options="roles"
                value-field="id"
                text-field="name"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Branch">
              <b-form-select
                v-model="form.branch_id"
                :options="branches"
                value-field="id"
                text-field="name"
              ></b-form-select>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>

    <b-modal
      id="edit-user-modal"
      ref="modal"
      size="xl"
      @hidden="resetModal"
      @ok="updateuser"
      title="Edit User"
    >
      <form ref="editform" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6">
            <b-form-group label="First Name">
              <b-form-input v-model="form.first_name"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Last Name">
              <b-form-input v-model="form.last_name"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Email">
              <b-form-input v-model="form.email"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Role">
              <b-form-select
                v-model="form.role"
                :options="roles"
                value-field="id"
                text-field="name"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Branch">
              <b-form-select
                v-model="form.branch_id"
                :options="branches"
                value-field="id"
                text-field="name"
              ></b-form-select>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <b-modal
      id="delete-user-modal"
      ref="modal"
      size="md"
      @hidden="resetModal"
      @ok="confirmDelete"
      title="Confirm Delete Actions!"
    >
     <p class="text-center">
        Are you sure? Once the user is deleted, you will not be ale to access the user account. 
     </p>
    </b-modal>
    <loading :active.sync="isLoading"></loading>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      users: [],
      selectedUserId:0,
      branches: [],
      roles: [],
      form: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        role: null,
        branch_id: null,
      },
      isLoading: false,
      fields: [
        {
          key: "name",
          label: "Name",
          formatter: (value, key, item) => {
            if (item.type == 1) {
              return item.first_name + " " + item.last_name;
            } else {
              return item.business_name;
            }
          },
        },
        { key: "email", label: "Email" },
        { key: "branch", label: "Branch" },
        {
          key: "role",
          label: "Role",
          formatter: (value) => {
            if (this.roles.filter((x) => x.id == value).length > 0) {
              return this.roles.filter((x) => x.id == value)[0].name;
            } else {
              return "";
            }
          },
        },
,
        { key: "id", label: "Actions" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Users" }]);
    this.getAllUsers();
    this.getAllBranches();
    this.rolesForUser();
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    rolesForUser() {
      if (this.currentUser.role == 1) {
        this.roles = [
          { id: 2, name: "Branch Manager" },
          { id: 3, name: "Staff" },
        ];
      } else if (this.currentUser.role == 2) {
        this.roles = [{ id: 3, name: "Staff" }];
      }
    },
    getAllUsers() {
      this.isLoading = true;
      if (this.currentUser.role == 1) {
        ApiService.get("getallusers")
          .then(({ data }) => {
            
            this.users = data;
            this.isLoading = false;
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      } else if (this.currentUser.role == 2) {
        ApiService.get("getbranchusers/" + this.currentUser.branch_id)
          .then(({ data }) => {
            this.users = data;
            this.isLoading = false;
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    },
    checkErrors() {
      if (
        this.form.first_name &&
        this.form.email &&
        this.form.password &&
        this.form.role &&
        this.form.branch_id
      ) {
        return true;
      }

      this.errors = [];

      if (!this.form.first_name) {
        this.errors.push("First name is required.");
      }
      if (!this.form.email) {
        this.errors.push("Email is required.");
      }
      if (!this.form.password) {
        this.errors.push("Password is required.");
      }
      if (!this.form.role) {
        this.errors.push("Role is required.");
      }
      if (!this.form.branch_id) {
        this.errors.push("Branch is required.");
      }
      for (let field of this.errors) {
        this.makeToastVariant("danger", field, "Error");
      }
    },
    checkErrorsUpdate() {
      if (
        this.form.first_name &&
        this.form.email &&
        this.form.role &&
        this.form.branch_id
      ) {
        return true;
      }

      this.errors = [];

      if (!this.form.first_name) {
        this.errors.push("First name is required.");
      }
      if (!this.form.email) {
        this.errors.push("Email is required.");
      }
      if (!this.form.role) {
        this.errors.push("Role is required.");
      }
      if (!this.form.branch_id) {
        this.errors.push("Branch is required.");
      }
      for (let field of this.errors) {
        this.makeToastVariant("danger", field, "Error");
      }
    },
    adduser(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.checkErrors() == true) {
        this.isLoading = true;
        ApiService.post("adduser", this.form)
          .then(({ data }) => {
            
            if(data.success){
              debugger;
              if(data.validemail == false){
                  this.makeToastVariant(
                  "danger",
                  "Email already in use. Please update your email to create new account",
                  "Error"
                );
                this.isLoading = false;
                return false;
              }else{
                this.getAllUsers();
                this.$nextTick(() => {
                  this.$bvModal.hide("add-user-modal");
                  this.makeToastVariant(
                    "success",
                    "User added successfully",
                    "Success"
                  );
                });
              }
            }
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    },
    updateuser(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.checkErrorsUpdate() == true) {
        this.isLoading = true;
        ApiService.update("updateuser", this.form.id, this.form)
          .then(() => {
            this.getAllUsers();
            this.$nextTick(() => {
              this.$bvModal.hide("edit-user-modal");
              this.makeToastVariant(
                "info",
                "User updated successfully",
                "Success"
              );
            });
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    },
    resetModal() {
      this.form.first_name = null;
      this.form.last_name = null;
      this.form.email = null;
      this.form.password = null;
      this.form.role = null;
      this.form.branch_id = null;
    },
    makeToastVariant(variant = null, text, title) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    ShowEditModal(id) {
      this.isLoading = true;
      ApiService.get("getuser", id)
        .then(({ data }) => {
          this.form = data;
          this.isLoading = false;
          this.$bvModal.show("edit-user-modal");
        })
        .catch(() => {
          this.isLoading = false;
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    getAllBranches() {
      ApiService.get("branch")
        .then(({ data }) => {
          if (this.currentUser.role == 1) {
            this.branches = data;
          } else {
            for (let index = 0; index < data.length; index++) {
              if (data[index].id == this.currentUser.branch_id) {
                this.branches[0] = data[index];
              }
            }
          }
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    deleteUserModal(id) {
      this.selectedUserId = id;
      this.$bvModal.show("delete-user-modal");
    },
    confirmDelete(){
      ApiService.delete("deleteuser", this.selectedUserId)
        .then(() => {
          this.getAllUsers();
          this.makeToastVariant(
            "warning",
            "User deleted successfully",
            "Success"
          );
        })
        .catch(() => {
         
        });
    }
  },
};
</script>
